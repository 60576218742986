import { Component, inject } from '@angular/core';
import { IcSettingsService } from '@item-cache';
import { VudAlertModule } from '@vismaux/ngx-vud';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'di-segment-error',
  templateUrl: './segment-error.component.html',
  imports: [VudAlertModule],
})
export class SegmentErrorComponent {
  protected readonly errorMessage = inject(IcSettingsService).segmentError;

  backToHome() {
    window.location.href = environment.vismaHomeUrl;
  }
}
