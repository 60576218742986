import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { IcSettingsService } from '@item-cache';
import { Observable, filter, map } from 'rxjs';

import { environment } from '../../../environments/environment';
import { getPreferredOdpOrgId } from '../../../href.helper';

export const OnboardingGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const needsOnboarding = inject(IcSettingsService).needsOnboarding.asReadonly();

  // Wait for initialization to be sure that the needsOnboarding is set to a final value
  return toObservable(inject(IcSettingsService).isInitialized).pipe(
    filter((isInitialized) => isInitialized),
    map(() => {
      if (needsOnboarding()) {
        window.location.href = environment.vismaEmpmanUrl + '#/' + getPreferredOdpOrgId();
        return false;
      }
      return true;
    }),
  );
};

export const SegmentGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const segmentError = inject(IcSettingsService).segmentError.asReadonly();

  // Wait for initialization to be sure that the segmentError is set to a final value
  return toObservable(inject(IcSettingsService).isInitialized).pipe(
    filter((isInitialized) => isInitialized),
    map(() => {
      if (segmentError()) {
        router.navigate(['segment-error']);
        return false;
      }
      return true;
    }),
  );
};
