<div class="container pt-24">
  <div class="row error-page align-items-center">
    <div class="error-page-content col-12 col-md-6">
      <h1 i18n>Segment error</h1>
      <p i18n> The company segment configuration is invalid. Expected A2B3 or C3D3. Please contact support.</p>
      <p> {{ errorMessage() }} </p>
      <button class="btn btn-primary" (click)="backToHome()" i18n> Back to Visma.net Home</button>
    </div>
    <div class="error-page-image col-12 col-md-6">
      <img src="../../../assets/icons/error-access-denied.svg" alt="Access denied" />
    </div>
  </div>
</div>
