import { JsonPipe } from '@angular/common';
import { AfterViewInit, Component, TemplateRef, ViewChild, inject } from '@angular/core';
import { VudErrorTooltipModule } from '@vismaux/ngx-vud';

import { ToasterService } from '#shared/components/toaster/toaster.service';

@Component({
  selector: 'di-toaster',
  templateUrl: './toaster-templates.component.html',
  imports: [VudErrorTooltipModule, JsonPipe],
})
export class ToasterTemplatesComponent implements AfterViewInit {
  private readonly toaster = inject(ToasterService);

  @ViewChild('successTemplate') successTemplate!: TemplateRef<any>;
  @ViewChild('infoTemplate') infoTemplate!: TemplateRef<any>;
  @ViewChild('errorTemplate') errorTemplate!: TemplateRef<any>;
  @ViewChild('warningTemplate') warningTemplate!: TemplateRef<any>;

  ngAfterViewInit() {
    this.toaster.setTemplate('success', this.successTemplate);
    this.toaster.setTemplate('info', this.infoTemplate);
    this.toaster.setTemplate('danger', this.errorTemplate);
    this.toaster.setTemplate('warning', this.warningTemplate);
  }

  hide(id: string) {
    this.toaster.hide(id);
  }
}
