import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { LayoutService } from '#core/services/layout.service';
import { LeftMenuService } from '#core/services/left-menu.service';

@Component({
  selector: 'di-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: false,
})
export class LayoutComponent {
  isMenuCollapsed$: Observable<boolean>;
  isIconMenu$: Observable<boolean>;
  isSideMenuAvailable$: Observable<boolean>;

  constructor(
    private leftMenuService: LeftMenuService,
    private layoutService: LayoutService,
  ) {
    this.isMenuCollapsed$ = this.leftMenuService.getIsMenuCollapsed();
    this.isSideMenuAvailable$ = this.leftMenuService.showSideMenu$;
    this.isIconMenu$ = this.leftMenuService.getIsIconMenu();
    this.layoutService.trackRouterChanges();
  }
}
