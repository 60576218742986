import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'di-payroll-portal',
  imports: [CommonModule],
  templateUrl: './payroll-portal.component.html',
  styleUrl: './payroll-portal.component.scss',
})
export class PayrollPortalComponent implements OnInit {
  ngOnInit() {
    window.location.href = environment.vismaCalendarUrl + 'absence/#/redirect/client_portal';
    // try {
    //   const orgId = getPreferredOdpOrgId();
    //   const componentRenderer = new ComponentRenderer({ orgId: Number(orgId) });
    //   componentRenderer
    //     .load([environment.payrollPortal])
    //     .then((renderer) => {
    //       let containerElement: HTMLElement;
    //       renderer.mount('absence|client-portal|view', { orgId: orgId }, containerElement);
    //     })
    //     .catch(this.handleError);
    // } catch (error) {
    //   this.handleError(error);
    // }
  }

  private handleError(error) {
    console.error('Failed to load or mount component', error);
    window.location.href = environment.vismaCalendarUrl + 'absence/#/redirect/client_portal';
  }
}
