import { Component } from '@angular/core';

import { LeftMenuService } from '../../core/services/left-menu.service';

@Component({
  selector: 'di-file-import-grid',
  templateUrl: './file-import-grid.component.html',
  styleUrls: ['./file-import-grid.component.scss'],
  standalone: false,
})
export class FileImportGridComponent {
  constructor(private leftMenuService: LeftMenuService) {
    this.leftMenuService.toggleMenuCollapse(true);
  }

  navigateTo(url: string): void {
    window.open(url, '_blank');
  }
}
